<template>
  <v-card class="mx-auto fill-height">
    <v-img contain :src="require('@/assets/images/philt3r-rain-room.jpg')">
        <v-container fill-height>
          <v-row align="center" class="white--text mx-auto" justify="center">
              <v-col class="white--text text-center" cols="12">
              <span :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']">
                  @philt3r
              </span>
              <br>
              <br>
              <span :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-2']" class="font-weight-black">
                  Agent of Anarchy
              </span>
              <br>
              </v-col>
          </v-row>
        </v-container>
      </v-img>
  </v-card>
</template>

<script>

export default {
  name: 'Home',

  components: {
  }
}
</script>
